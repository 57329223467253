import delivery from "../../images/icons/delivery.png";
import exchange from "../../images/icons/exchange.png";
import card from "../../images/icons/card.png";
import rightRedArrow from "../../images/icons/rightRedArrow.png";

import addcart from "../../images/icons/addcart.png";
import newsletter from "../../images/icons/newsletter.png";

import wishListIcon from "../../images/icons/wishlist.png";
import activeWishListIcon from "../../images/icons/inWishList.png";

import { Link, useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Pagination, Navigation } from 'swiper/core';
import "swiper/css";
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { useEffect, useState } from "react";
import { Global } from "../../Variables/Global";
import axios from "axios";
import Util from "../../Classes/Util";
import { toast } from 'react-toastify';
import Product from "../Products/Product";
import Cookies from "js-cookie";

const Home = (props) => {
  const [slider1, setSlider1] = useState([]);
  const [slider2, setSlider2] = useState([]);
  const [widget1, setWidget1] = useState([]);
  const [widget2, setWidget2] = useState([]);
  const [widget3, setWidget3] = useState([]);
  const [widget4, setWidget4] = useState([]);
  const [widget5, setWidget5] = useState([]);
  const [widget6, setWidget6] = useState([]);
  const [widget7, setWidget7] = useState([]);
  const [widget8, setWidget8] = useState([]);
  const [wName6, setWName6] = useState("");
  const [featured, setFeatured] = useState([]);
  const [onsale, setOnsale] = useState([]);
  const [toprated, setToprated] = useState([]);
  const [bestSeller, setBestSeller] = useState([]);
  const [lowStock, setLowStock] = useState([]);
  const [categoryWidget, setCatgeryWidget] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [isLogged, setIsLogged] = useState(false);
  const [WishListItems, setWishListItems] = useState([]);
  const [wishListClicked, setWishListClicked] = useState(false);
  const [emailNewsLetter, setEmailNewsLetter] = useState("");
  const navigate = useNavigate();
  SwiperCore.use([Autoplay, Pagination, Navigation]);

  var emailregx = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  function isValid() {
    if (emailNewsLetter === "") {
      toast.error("please Provide your Email");
      return false;
    } else if (!emailregx.test(emailNewsLetter)) {
      toast.error("Bad email format");
    } else {
      return true;
    }

  }
  const getSliders = async (sliderId) => {
    // setIsLoading(true)
    const URL = Global.API_URL + "Slider/GetSlidesBySliderId/" + sliderId;
    await fetch(URL, {
      method: "Get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": Global.API_URL,
      },
    })
      .then((resp) => {
        if (resp.ok) {
          return resp.json();
        }
      })
      .then((result) => {
        switch (sliderId) {
          case 1:
            setSlider1(result)
            break
          case 2:
            setSlider2(result)
            break
        }
      });
  }

  const getWidgets = async (widgetId) => {
    // setIsLoading(true)
    const URL = Global.API_URL + "Widgets/GetAssignedCategories/" + widgetId;
    await fetch(URL, {
      method: "Get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": Global.API_URL,
      },
    })
      .then((resp) => {
        if (resp.ok) {
          return resp.json();
        }
      })
      .then((result) => {
        switch (widgetId) {
          case 1:
            setWidget1(result)
            break
          case 2:
            setWidget2(result)
            break
          case 3:
            setWidget3(result)
            getProductByCategoryId(result[0].CategoryId, 3);
            break
          case 4:
            setWidget4(result)
            getProductByCategoryId(result[0].CategoryId, 4);
            break
          case 5:
            setWidget5(result)
            getProductByCategoryId(result[0].CategoryId, 5);
            break
          case 6:
            setWidget6(result)
            setWName6(result[0].Title)
            break
          case 7:
            setWidget7(result)
            getProductByCategoryId(result[0].CategoryId, 7);
            break
          case 8:
            setWidget8(result)
            getProductByCategoryId(result[0].CategoryId, 8);
            break
        }
      });

    // setIsLoading(false)
  }


  const getProductByCategoryId = async (categoryId, widgetId) => {
    var groupId;
    if (Cookies.get("WishList") !== undefined) {
      groupId = 4
    } else {
      groupId = 3
    }
    const offset = new Date().getTimezoneOffset();
    fetch(Global.API_URL + "Products/GetProductsByCategoryIdWeb", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": Global.API_URL,
      },
      body: JSON.stringify({
        categoryId: categoryId,
        pageId: 0,
        count: 7,
        offset: offset,
        attributes: [],
        categories: [],
        prices: [],
        groupId: groupId,
        countryId: 125,
      }),
    })
      .then((Response) => {
        if (Response.ok) return Response.json();
        else throw Error("Did Not Receive expected data");
      })
      .then((Result) => {
        switch (widgetId) {
          case 3:
            setFeatured(Result.finalList)
            break
          case 4:
            setOnsale(Result.finalList)
            break
          case 5:
            setToprated(Result.finalList)
            break
          case 7:
            setBestSeller(Result.finalList)
            break
          case 8:
            setLowStock(Result.finalList)
            break
        }
      })
    // const res = await axios.get("https://geolocation-db.com/json/");
    // var country;
    // fetch(Global.API_URL + "Country/GetCoutryByCountryCode/" + res.data.country_code, {
    //   method: "GET",
    //   headers: {
    //     Accept: "application/json",
    //     "Content-Type": "application/json",
    //   },
    // })
    //   .then((Response) => {
    //     if (Response.ok) {
    //       return Response.json();
    //     } else {
    //       return ""
    //     }
    //   })
    //   .then((Result) => {
    //     country = Result.CountryId
      
    //   })
  }

  const HandleSubmit = async (ProductId, ProductVariationId) => {
    if (isLogged) {

      // const getItem = (localStorage.getItem('Cart') !== null);
      const getItem = (Cookies.get("Cart") !== undefined);
      const token = Cookies.get("CustomerToken");
      if(getItem){
        // var CartId = Util.decrypt(localStorage.getItem("Cart"));
        const CartId = Cookies.get("Cart");
        let Response = await fetch(Global.API_URL + "Cart/AddItemToCart",
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Authorization": `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": Global.API_URL,
            },
  
            body: JSON.stringify({
              CartId: CartId,
              ProductId: ProductId,
              VariationId: ProductVariationId,
              Quantity: 1
            })
          }
        ).then((response) => {
          if (response.ok) {
            toast.success("Item added to cart");
            return response.json();
          } else {
            return Response.text().then((text) => {
              throw new Error(text);
            });
          }
        }).catch((error) => {
          toast.error("Could Not Add");
        })
      }
     
    } else {
      navigate('/UserAuthentication')
    }
  }
  const HandleAddToWishList = async (ProductId, ProductVariationId) => {
    if (isLogged) {
      // setWishListClicked(!wishListClicked);
      // const getItem = (localStorage.getItem('WishList') !== null);
      const getItem = (Cookies.get('WishList') !== undefined);
      if(getItem){
        // var WishlistId = Util.decrypt(localStorage.getItem("WishList"));
        var WishlistId = Cookies.get('WishList');
        const token = Cookies.get("CustomerToken");
      let Response = await fetch(Global.API_URL + "Wishlist/AddItemToWishlist",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": Global.API_URL,
          },

          body: JSON.stringify({
            WishlistId: WishlistId,
            ProductId: ProductId,
            VariationId: ProductVariationId,
          })
        }
      ).then((response) => {
        if (response.ok) {
          toast.success("Item added to wishList");
          return response.json();
        } else {
          return Response.text().then((text) => {
            throw new Error(text);
          });
        }
      }).catch((error) => {
        toast.error("Could Not Add");
      })
      }
      
    } else {
      navigate('/UserAuthentication');
    }
  }

  const HandleRemoveFromWishList = async (ProductId) => {
    if (isLogged) {
      // setWishListClicked(!wishListClicked);
      // const getItem = (localStorage.getItem('WishList') !== null);
      const getItem = (Cookies.get('WishList') !== undefined);
      if(getItem){
        // var WishlistId = Util.decrypt(localStorage.getItem("WishList"));
        const WishlistId = Cookies.get("WishList");
        const token = Cookies.get("CustomerToken");
      let Response = await fetch(Global.API_URL + "Wishlist/RemoveItemFromWishlist" + "/" + ProductId + "/" + WishlistId,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": Global.API_URL,
          }
        }
      ).then((response) => {
        if (response.ok) {
          toast.success("Item Removed from wishList");
          return response.json();
        } else {
          return Response.text().then((text) => {
            throw new Error(text);
          });
        }
      }).catch((error) => {
        toast.error("Could Not Remove");
      })
      }

      
    } else {
      navigate('/UserAuthentication');
    }
  }
  const getWishListItems = async () => {
    var groupId;
    if (Cookies.get("WishList") !== undefined) {
      groupId = 4
    } else {
      groupId = 3
    }
    const offset = new Date().getTimezoneOffset();
    // const getItem = (localStorage.getItem('WishList') !== null);
    const getItem = (Cookies.get('WishList') !== undefined);
    if(getItem){
      // var WishlistId = Util.decrypt(localStorage.getItem("WishList"));
      var WishlistId =Cookies.get("WishList");
    const token = Cookies.get("CustomerToken");

      fetch(Global.API_URL + "Wishlist/GetWishistItems/" + WishlistId + "/" + offset + "/" + 125 + "/" + groupId, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }).then((response) => {
        if (response.ok) {
          return response.json();
        }
      }).then((Result) => {
        setWishListItems(Result.WishlistItems);
      });
    }
    // const res = await axios.get("https://geolocation-db.com/json/");
    // var country;
    // fetch(Global.API_URL + "Country/GetCoutryByCountryCode/" + res.data.country_code, {
    //   method: "GET",
    //   headers: {
    //     Accept: "application/json",
    //     "Content-Type": "application/json",
    //   },
    // })
    //   .then((Response) => {
    //     if (Response.ok) {
    //       return Response.json();
    //     } else {
    //       return ""
    //     }
    //   })
    //   .then((Result) => {
    //     country = Result.CountryId
 
      
    //   })

  };

  const handleSubmitNewsLetter = (e) => {
    e.preventDefault();
    if (isValid()) {
      fetch(Global.API_URL + "NewsLetter/SubscribeToNewsLetter", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": Global.API_URL,
        },
        body: JSON.stringify({
          Email: emailNewsLetter,
        }),
      })
        .then((response) => {
          if (response.ok) {
            toast.success("Subscribed Successfully");
            return response.json();
          }
          return response.text().then((text) => {
            throw new Error(text);
          });
        })
    }

  }


  useEffect(() => {
    props.setHeaderTitle("Home")
    props.setFooterActive(1);
    window.scrollTo(0, 0);
    setIsLoading(true);
    getSliders(1);
    getSliders(2);
    getWidgets(1);
    getWidgets(2);
    getWidgets(3);
    getWidgets(4);
    getWidgets(5);
    getWidgets(6);
    getWidgets(7);
    getWidgets(8);
    getWishListItems();
    if (Cookies.get("CustomerToken") === undefined) {
      setIsLogged(false);
    } else {
      setIsLogged(true);
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 2500);
  }, [props])

  return (
    <>
      {isLoading ? (
        <div className="spinner-container">
          <div className="loading-spinner"></div>
        </div>
      ) : (
        <>
          {slider1.map((first) => {
            return (
              <Swiper key={first.SliderName} className='mainSlider' spaceBetween={50} slidesPerView={1}
                autoplay={{
                  "delay": 4000,
                  "disableOnInteraction": false
                }}>
                {first.slides.map((slide) => {
                  return (
                    <SwiperSlide key={slide.SlideName}>
                      <Link to={slide.Url}>
                        <img src={Global.PHOTO_URL + slide.SlideImage} />
                      </Link>
                    </SwiperSlide>
                  )
                })}
              </Swiper>

            )
          })}

          <div className="container infoBar d-none d-md-block">
            <div className="row">
              <div className="col-12 col-md-4">
                <div className="item">
                  <div className="icon">
                    <img src={delivery} alt="Delivery"></img>
                  </div>
                  <div className="text">
                    <h5>Delivery</h5>
                    <p>FREE DELIVERY ALL OVER LEBANON </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4 middleBar">
                <div className="item">
                  <div className="icon">
                    <img src={exchange} alt="Delivery"></img>
                  </div>
                  <div className="text">
                    <h5>Exchange</h5>
                    <p>3 days to exchange product</p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="item">
                  <div className="icon">
                    <img src={card} alt="Card"></img>
                  </div>
                  <div className="text">
                    <h5>Payment</h5>
                    <p>we accept online payments</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container catergorySection">
            <div className="row">
              <div className="col-12 col-md-6">
                {widget1.map((w, index) => {
                  return (
                    <Link key={index} to={'/Category/' + w.CategoryName.replace(/ /g, "-") + '/' + w.CategoryId}>
                      <img src={Global.PHOTO_URL + w.CategoryImage} alt="Category 1" />
                      <div className="box">
                        <div className="content">
                          <h3>{w.CategoryName}</h3>
                          <span to={'/Category/' + w.CategoryName.replace(/ /g, "-") + '/' + w.CategoryId} className="linkto">Start Buying</span>
                        </div>
                      </div>
                    </Link>
                  )
                })}
              </div>
              <div className="col-12 col-md-6">
                {widget2.map((w, index) => {
                  return (
                    <Link key={index} to={'/Category/' + w.CategoryName.replace(/ /g, "-") + '/' + w.CategoryId}>
                      <img src={Global.PHOTO_URL + w.CategoryImage} alt="Category 2" />
                      <div className="box">
                        <div className="content">
                          <h3>{w.CategoryName}</h3>
                          <span to={'/Category/' + w.CategoryName.replace(/ /g, "-") + '/' + w.CategoryId} className="linkto">Start Buying</span>
                        </div>
                      </div>
                    </Link>
                  )
                })}
              </div>
            </div>
          </div>

          <div className="container tabSection">
            <div className="row">
              <div className={categoryWidget === 1 ? "col-4 col-md-4 active" : "col-4 col-md-4"}>
                <h4 onClick={() => setCatgeryWidget(1)}>{widget3.map((w, index) => <p key={index}>{w.Title}</p>)}</h4>
              </div>
              <div className={categoryWidget === 2 ? "col-4 col-md-4 active" : "col-4 col-md-4"}>
                <h4 onClick={() => setCatgeryWidget(2)}>{widget4.map((w, index) => <p key={index}>{w.Title}</p>)}</h4>
              </div>
              <div className={categoryWidget === 3 ? "col-4 col-md-4 active" : "col-4 col-md-4"}>
                <h4 onClick={() => setCatgeryWidget(3)}>{widget5.map((w, index) => <p key={index}>{w.Title}</p>)}</h4>
              </div>
            </div>
            {categoryWidget == 1 ? (
              <>
                <div className="row">
                  <div className="col-12 col-md-12">
                    {widget3.map((w, index) => {
                      return (
                        <Swiper
                          key={index}
                          className="productsSlider"
                          spaceBetween={50}
                          breakpoints={{
                            0: {
                              slidesPerView: 1,
                            },
                            500: {
                              slidesPerView: 1,
                            },
                            600: {
                              slidesPerView: 2,
                            },
                            800: {
                              slidesPerView: 2,
                            },
                            1100: {
                              slidesPerView: 3,
                            },
                            1200: {
                              slidesPerView: 3,
                            },
                            1300: {
                              slidesPerView: 4
                            },
                          }}
                        >
                          {featured.map((product, index) => {
                            return (
                              <SwiperSlide key={index}>
                                <div className="item" key={product.ProductId}>
                                  <div className="imgBox">
                                    <Link to={"/DetailedProduct/" + product.ProductName.replace(/ /g, "-") + "/" + product.ProductId + "/" + product.DefaultVariation.ProductVariationId} >
                                      <img src={Global.PHOTO_URL + product.DefaultVariation.Image1} alt={product.ProductName} />
                                      {!product.InStock && (
                            <div className="out-of-stock-overlay">Out of Stock</div>
                        )}
                                    </Link>
                                  </div>
                                  
                                  {
                                  
                                    WishListItems.find(obj => obj.ProductId === product.ProductId) ? (
                                      <button className="btnWishList"
                                        onClick={() => HandleRemoveFromWishList(product.ProductId)}
                                      >
                                        <img
                                          src={activeWishListIcon}
                                          alt="In WishList"
                                        ></img>
                                      </button>
                                    ) : (
                                      <button className="btnWishList"
                                        onClick={() => HandleAddToWishList(product.ProductId, product.DefaultVariation.ProductVariationId)}
                                      >
                                        <img src={wishListIcon} alt="WishList"></img>
                                      </button>
                                    )
                                  }
                                  <div className="content">
                                    <Link  to={"/DetailedProduct/" + product.ProductName.replace(/ /g, "-") + "/" + product.ProductId + "/" + product.DefaultVariation.ProductVariationId}><h5>{product.ProductName}</h5></Link>
                                    {/* <p className="sub">Playmegastore</p> */}
                                    {product.DefaultVariation.SalePrice === 0 ? (
                                      <div>
                                        <div>
                                          <p className="pricenormal" style={{ minHeight: '40px' }}>
                                            ${product.DefaultVariation.RegularPrice}{" "}
                                            {/* <button
                                              onClick={() => HandleSubmit(product.ProductId, product.DefaultVariation.ProductVariationId)}
                                            >
                                              <img src={addcart} alt="Add to Cart"></img>
                                            </button> */}
                                          </p>
                                        </div>
                                        <div className="text-center">
                                          <Link to={"/DetailedProduct/" + product.ProductName.replace(/ /g, "-") + "/" + product.ProductId + "/" + product.DefaultVariation.ProductVariationId}>
                                            <span className="btn btn-default choose-selection-button">Add to cart</span>
                                          </Link>
                                        </div>
                                      </div>
                                    ) : (
                                      <>
                                        <p className="price" style={{ minHeight: '40px' }}>
                                          <span className="off">-{product.DefaultVariation.Percentage.toFixed(2) + "%"}</span>
                                          <span className="was"><span className="fw-medium">Was:</span> <strike>${product.DefaultVariation.RegularPrice}</strike></span>
                                          ${product.DefaultVariation.SalePrice}{" "}
                                          <button
                                            onClick={() => HandleSubmit(product.ProductId, product.DefaultVariation.ProductVariationId)}
                                          >
                                            <img src={addcart} alt="Add to Cart"></img>
                                          </button>
                                        </p>
                                        <div className="text-center">
                                          <Link to={"/DetailedProduct/" + product.ProductName.replace(/ /g, "-") + "/" + product.ProductId + "/" + product.DefaultVariation.ProductVariationId}>
                                            <span className="btn btn-default choose-selection-button">Add to cart</span>
                                          </Link>
                                        </div>
                                      </>
                                    )}
                                  </div>
                                </div>
                              </SwiperSlide>
                            )
                          })}
                        </Swiper>
                      )
                    })}
                  </div>
                </div>
              </>
            ) : null}
            {categoryWidget == 2 ? (
              <>
                <div className="row">
                  <div className="col-12 col-md-12">
                    {widget4.map((w, index) => {
                      return (
                        <Swiper
                          key={index}
                          className="productsSlider"
                          spaceBetween={50}
                          breakpoints={{
                            0: {
                              slidesPerView: 1,
                            },
                            500: {
                              slidesPerView: 1,
                            },
                            600: {
                              slidesPerView: 2,
                            },
                            800: {
                              slidesPerView: 2,
                            },
                            1100: {
                              slidesPerView: 3,
                            },
                            1200: {
                              slidesPerView: 3,
                            },
                            1300: {
                              slidesPerView: 4
                            },
                          }}
                        >
                          {onsale.map((product, index) => {

                            return (
                              <SwiperSlide key={index}>
                                <div className="item" key={product.ProductId}>
                                  <div className="imgBox">
                                    <Link to={"/DetailedProduct/" + product.ProductName.replace(/ /g, "-") + "/" + product.ProductId + "/" + product.DefaultVariation.ProductVariationId} >
                                      <img src={Global.PHOTO_URL + product.DefaultVariation.Image1} alt={product.ProductName} />
                                      {!product.InStock && (
                            <div className="out-of-stock-overlay">Out of Stock</div>
                        )}
                                    </Link>
                                  </div>
                                  {/* <button className="btnWishList">
                                    <img src={wishListIcon} alt="WishList"></img>
                                    <img
                                      src={activeWishListIcon}
                                      alt="In WishList"
                                      style={{ display: "none" }}
                                    ></img>
                                  </button> */}
                                  {
                                    // WishListItems.find(item => {
                                    //   const resultfind = item.find((wishlist) => {
                                    //     return wishlist.ProductId === product.ProductId;
                                    //   })
                                    WishListItems.find(obj => obj.ProductId === product.ProductId) ? (
                                      <button className="btnWishList"
                                        onClick={() => HandleRemoveFromWishList(product.ProductId)}
                                      >
                                        <img
                                          src={activeWishListIcon}
                                          alt="In WishList"
                                        ></img>
                                      </button>
                                    ) : (
                                      <button className="btnWishList"
                                        onClick={() => HandleAddToWishList(product.ProductId, product.DefaultVariation.ProductVariationId)}
                                      >
                                        <img src={wishListIcon} alt="WishList"></img>
                                      </button>
                                    )
                                  }
                                  <div className="content">
                                    <Link  to={"/DetailedProduct/" + product.ProductName.replace(/ /g, "-") + "/" + product.ProductId + "/" + product.DefaultVariation.ProductVariationId}><h5>{product.ProductName}</h5></Link>
                                    {/* <p className="sub">Playmegastore</p> */}
                                    {product.DefaultVariation.SalePrice === 0 ? (
                                      <div>
                                        <div>
                                          <div className="pricenormal">
                                            ${product.DefaultVariation.RegularPrice}{" "}
                                            {/* <button
                                          onClick={() => HandleSubmit(product.ProductId, product.DefaultVariation.ProductVariationId)}
                                        >
                                          <img src={addcart} alt="Add to Cart"></img>
                                        </button> */}
                                            <p className="price" style={{ minHeight: '50px' }}>
                                              <span className="off"></span>
                                              <span className="was"><span className="fw-medium"></span> <strike></strike></span>
                                            </p>
                                          </div>
                                        </div>
                                        <div className="text-center">
                                          <Link to={"/DetailedProduct/" + product.ProductName.replace(/ /g, "-") + "/" + product.ProductId + "/" + product.DefaultVariation.ProductVariationId}>
                                            <span className="btn btn-default choose-selection-button">Add to cart</span>
                                          </Link>
                                        </div>
                                      </div>
                                    ) : (
                                      <>
                                        <p className="price">
                                          <span className="off">-{product.DefaultVariation.Percentage.toFixed(2) + "%"}</span>
                                          <span className="was"><span className="fw-medium">Was:</span> <strike>${product.DefaultVariation.RegularPrice}</strike></span>
                                          ${product.DefaultVariation.SalePrice}{" "}
                                          {/* <button
                                          onClick={() => HandleSubmit(product.ProductId, product.DefaultVariation.ProductVariationId)}
                                        >
                                          <img src={addcart} alt="Add to Cart"></img>
                                        </button> */}
                                        </p>
                                        <div className="text-center">
                                          <Link to={"/DetailedProduct/" + product.ProductName.replace(/ /g, "-") + "/" + product.ProductId + "/" + product.DefaultVariation.ProductVariationId}>
                                            <span className="btn btn-default choose-selection-button">Add to cart</span>
                                          </Link>
                                        </div>
                                      </>
                                    )}
                                  </div>
                                </div>
                              </SwiperSlide>
                            )
                          })}
                        </Swiper>
                      )
                    })}
                  </div>
                </div>
              </>
            ) : null}
            {categoryWidget == 3 ? (
              <>
                <div className="row">
                  <div className="col-12 col-md-12">
                    {widget5.map((w, index) => {
                      return (
                        <Swiper
                          key={index}
                          className="productsSlider"
                          spaceBetween={50}
                          breakpoints={{
                            0: {
                              slidesPerView: 1,
                            },
                            500: {
                              slidesPerView: 1,
                            },
                            600: {
                              slidesPerView: 2,
                            },
                            800: {
                              slidesPerView: 2,
                            },
                            1100: {
                              slidesPerView: 3,
                            },
                            1200: {
                              slidesPerView: 3,
                            },
                            1300: {
                              slidesPerView: 4
                            },
                          }}
                        >
                          {toprated.map((product, index) => {
                            return (
                              <SwiperSlide key={index}>
                                <div className="item" key={index}>
                                  <div className="imgBox">
                                    {/* <Link to={"/DetailedProduct/" + product.ProductName.replace(/ /g, "-") + "/" + product.ProductId + "/" + product.DefaultVariation.ProductVariationId} >
                                      <img src={Global.PHOTO_URL + product.DefaultVariation.Image1} alt={product.ProductName} />
                                    </Link> */}
                                    {
                                      product.DefaultVariation.Image2 == "" ? (
                                        <Link to={"/DetailedProduct/" + product.ProductName.replace(/ /g, "-") + "/" + product.ProductId + "/" + product.DefaultVariation.ProductVariationId}>
                                          <img src={Global.PHOTO_URL + product.DefaultVariation.Image1} alt={product.ProductName} />
                                          {/* onMouseOver={e => (e.currentTarget.src = Global.PHOTO_URL + product.Image1)}
                              onMouseOut = {e => (e.currentTarget.src = Global.PHOTO_URL + product.Image0)}  */}
                               {!product.InStock && (
                            <div className="out-of-stock-overlay">Out of Stock</div>
                        )}
                                        </Link>
                                      ) : (
                                        <Link to={"/DetailedProduct/" + product.ProductName.replace(/ /g, "-") + "/" + product.ProductId + "/" + product.DefaultVariation.ProductVariationId}>
                                          <img src={Global.PHOTO_URL + product.DefaultVariation.Image1} alt={product.ProductName}
                                            onMouseOver={e => (e.currentTarget.src = Global.PHOTO_URL + product.DefaultVariation.Image2)}
                                            onMouseOut={e => (e.currentTarget.src = Global.PHOTO_URL + product.DefaultVariation.Image1)}
                                          />
 {!product.InStock && (
                            <div className="out-of-stock-overlay">Out of Stock</div>
                        )}
                                        </Link>
                                      )
                                    }
                                  </div>
                                  {/* <button className="btnWishList">
                                    <img src={wishListIcon} alt="WishList"></img>
                                    <img
                                      src={activeWishListIcon}
                                      alt="In WishList"
                                      style={{ display: "none" }}
                                    ></img>
                                  </button> */}
                                  {
                                    // WishListItems.find(item => {
                                    //   const resultfind = item.find((wishlist) => {
                                    //     return wishlist.ProductId === product.ProductId;
                                    //   })
                                    WishListItems.find(obj => obj.ProductId === product.ProductId) ? (
                                      <button className="btnWishList"
                                        onClick={() => HandleRemoveFromWishList(product.ProductId)}
                                      >
                                        <img
                                          src={activeWishListIcon}
                                          alt="In WishList"
                                        ></img>
                                      </button>
                                    ) : (
                                      <button className="btnWishList"
                                        onClick={() => HandleAddToWishList(product.ProductId, product.DefaultVariation.ProductVariationId)}
                                      >
                                        <img src={wishListIcon} alt="WishList"></img>
                                      </button>
                                    )
                                  }
                                  <div className="content">
                                    <Link to={"/DetailedProduct/" + product.ProductName.replace(/ /g, "-") + "/" + product.ProductId + "/" + product.DefaultVariation.ProductVariationId}><h5>{product.ProductName}</h5></Link>
                                    {product.DefaultVariation.SalePrice === 0 ? (
                                      <div>
                                        <div>
                                          <div className="pricenormal">
                                            ${product.DefaultVariation.RegularPrice}{" "}
                                            {/* <button
                                          onClick={() => HandleSubmit(product.ProductId, product.DefaultVariation.ProductVariationId)}
                                        >
                                          <img src={addcart} alt="Add to Cart"></img>
                                        </button> */}
                                            <p className="price" style={{ minHeight: '50px' }}>
                                              <span className="off"></span>
                                              <span className="was"><span className="fw-medium"></span> <strike></strike></span>
                                            </p>
                                          </div>
                                        </div>
                                        <div className="text-center">
                                          <Link to={"/DetailedProduct/" + product.ProductName.replace(/ /g, "-") + "/" + product.ProductId + "/" + product.DefaultVariation.ProductVariationId}>
                                            <span className="btn btn-default choose-selection-button">Add to cart</span>
                                          </Link>
                                        </div>
                                      </div>
                                    ) : (
                                      <>
                                        <p className="price">
                                          <span className="off">-{product.DefaultVariation.Percentage.toFixed(2) + "%"}</span>
                                          <span className="was"><span className="fw-medium">Was:</span> <strike>${product.DefaultVariation.RegularPrice}</strike></span>
                                          ${product.DefaultVariation.SalePrice}{" "}
                                          {/* <button
                                          onClick={() => HandleSubmit(product.ProductId, product.DefaultVariation.ProductVariationId)}
                                        >
                                          <img src={addcart} alt="Add to Cart"></img>
                                        </button> */}
                                        </p>
                                        <div className="text-center">
                                          <Link to={"/DetailedProduct/" + product.ProductName.replace(/ /g, "-") + "/" + product.ProductId + "/" + product.DefaultVariation.ProductVariationId}>
                                            <span className="btn btn-default choose-selection-button">Add to cart</span>
                                          </Link>
                                        </div>
                                      </>
                                    )}
                                  </div>
                                </div>
                              </SwiperSlide>
                            )
                          })}
                        </Swiper>
                      )
                    })}
                  </div>
                </div>
              </>
            ) : null}
          </div>

          <div className="container-fluid mt-3">
            <div className="row">
              <div className="col-12 p-0">
                {slider2.map((first, index) => {
                  return (
                    <Swiper key={index} className='' spaceBetween={50} slidesPerView={1}
                      autoplay={{
                        "delay": 4000,
                        "disableOnInteraction": false
                      }}>
                      {first.slides.map((slide, index) => {
                        return (
                          <SwiperSlide key={index}>
                            <Link to={slide.Url}>
                              <img src={Global.PHOTO_URL + slide.SlideImage} />
                            </Link>
                          </SwiperSlide>
                        )
                      })}
                    </Swiper>

                  )
                })}
              </div>
            </div>
          </div>

          <div className="container collectionSection">
            <div className="row titleBar">
              <div className="col-8 col-md-10">
                <h3>
                  {wName6.split(" ").length > 1 ? (
                    <>
                      <span>{wName6.split(' ')[0]}</span>{" "}{wName6.split(' ')[1]}
                    </>
                  ) : (
                    <span>{wName6}</span>
                  )}
                </h3>
              </div>
              {/* <div className="col-4 col-md-2 text-right">
                <Link to="/Collection">
                  View All
                  <span>
                    <img src={rightRedArrow} alt="Right Arrow" />
                  </span>
                </Link>
              </div> */}
            </div>
            <div className="row items d-none d-md-flex">
              {widget6.map((w, index) => {
                return (
                  <div key={index} className="col-12 col-md-6 col-lg-4 col-xl-3 text-center">
                    <Link to={"/Category/" + w.CategoryName.replace(/ /g, "-") + "/" + w.CategoryId}>
                      <img src={Global.PHOTO_URL + w.CategoryImage} alt="Collection" />
                      <p>{w.CategoryName}</p>
                    </Link>
                  </div>
                )
              })}
            </div>
            <div className="row items d-md-none">
              <div className="col-12">
                <div className="categoryScroll">
                  {widget6.map((w, index) => {
                    return (
                      <Link key={index} to={"/Category/" + w.CategoryName.replace(/ /g, "-") + "/" + w.CategoryId}>
                        <img src={Global.PHOTO_URL + w.CategoryImage} alt="Collection" />
                        <p>{w.CategoryName}</p>
                      </Link>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="bestsellerSection">
            <div className="container">
              {widget7.map((w, index) => {
                return (
                  <div key={index} className="row titleBar">
                    <div className="col-8 col-md-10">
                      <h3>
                        {w.Title.split(" ").length > 1 ? (
                          <>
                            <span>{w.Title.split(' ')[0]}</span>{" "}{w.Title.split(' ')[1]}
                          </>
                        ) : (
                          <span>{w.Title}</span>
                        )}
                      </h3>
                    </div>
                    <div className="col-4 col-md-2 text-right">
                      <Link to={"/Category/" + w.CategoryName.replace(/ /g, "-") + "/" + w.CategoryId}>
                        View All
                        <span>
                          <img src={rightRedArrow} alt="Right Arrow" />
                        </span>
                      </Link>
                    </div>
                  </div>
                )
              })}
              <div className="row">
                <div className="col-12 col-md-7">
                  <div className="row">
                    {bestSeller.slice(0, 6).map((product, index) => {
                      return (
                        <Product
                        key={index} 
                        product={product}
                        WishListItems={WishListItems}
                        HandleRemoveFromWishList={HandleRemoveFromWishList}
                        HandleAddToWishList={HandleAddToWishList}
                        />
                      )
                    })}
                  </div>
                </div>
                {bestSeller.slice(6, 7).map((product, index) => {

                  return (
                    <div className="col-12 col-md-5" key={index}>
                      <div className="singleItem">
                        <Link to={"/DetailedProduct/" + product.ProductName.replace(/ /g, "-") + "/" + product.ProductId + "/" + product.DefaultVariation.ProductVariationId}>
                          <img src={Global.PHOTO_URL + product.DefaultVariation.Image1} alt={product.ProductName} />
                          {!product.InStock && (
                            <div className="out-of-stock-overlay">Out of Stock</div>
                        )}
                        </Link>
                        <div className="content">
                          <h3>
                            {product.ProductName}
                            {/* <small>- Playmegastore</small> */}
                          </h3>
                          {product.DefaultVariation.SalePrice === 0 ? (
                            <div>
                              <div>
                                <div className="pricenormal">
                                  ${product.DefaultVariation.RegularPrice}{" "}
                                  {/* <button
                                onClick={() => HandleSubmit(product.ProductId, product.DefaultVariation.ProductVariationId)}
                              >
                                <img src={addcart} alt="Add to Cart"></img>
                              </button> */}
                                  <p className="price" style={{ minHeight: '50px' }}>
                                    <span className="off"></span>
                                    <span className="was"><span className="fw-medium"></span> <strike></strike></span>
                                  </p>
                                </div>
                              </div>
                              <div className="text-center">
                                <Link to={"/DetailedProduct/" + product.ProductName.replace(/ /g, "-") + "/" + product.ProductId + "/" + product.DefaultVariation.ProductVariationId}>
                                  <span className="btn btn-default choose-selection-button">Add to cart</span>
                                </Link>
                              </div>
                            </div>

                          ) : (
                            <>
                              <p className="price m-0">
                                ${product.DefaultVariation.SalePrice}{" "}
                                {/* <button
                                  onClick={() => HandleSubmit(product.ProductId, product.DefaultVariation.ProductVariationId)}
                                >
                                  <img src={addcart} alt="Add to Cart"></img>
                                </button> */}
                              </p>
                              <p className="price" style={{ minHeight: '50px' }}>
                                <span className="off">-{product.DefaultVariation.Percentage.toFixed(2) + "%"}</span>
                                <span className="was"><span className="fw-medium">Was:</span> <strike>${product.DefaultVariation.RegularPrice}</strike></span>
                              </p>
                              <div className="text-center">
                                <Link to={"/DetailedProduct/" + product.ProductName.replace(/ /g, "-") + "/" + product.ProductId + "/" + product.DefaultVariation.ProductVariationId}>
                                  <span className="btn btn-default choose-selection-button">Add to cart</span>
                                </Link>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>

          <div className="container lowStock mb-3">
            {widget8.map((w, index) => {
              return (
                <div key={index} className="row titleBar">
                  <div className="col-8 col-md-10">
                    <h3>
                      {w.Title.split(" ").length > 2 ? (
                        <>
                          <span>{w.Title.split(' ')[0] + " " + w.Title.split(' ')[1]}</span>{" "}{w.Title.split(' ')[2]}
                        </>
                      ) : (
                        <span>{w.Title}</span>
                      )}
                    </h3>
                  </div>
                  <div className="col-4 col-md-2 text-right">
                    <Link to={"/Category/" + w.CategoryName.replace(/ /g, "-") + "/" + w.CategoryId}>
                      View All
                      <span>
                        <img src={rightRedArrow} alt="Right Arrow" />
                      </span>
                    </Link>
                  </div>
                </div>
              )
            })}
            <div className="row tabSection">
              {widget8.map((w, index) => {
                return (
                  <Swiper
                    key={index}
                    className="productsSlider"
                    spaceBetween={50}
                    breakpoints={{
                      0: {
                        slidesPerView: 1,
                      },
                      500: {
                        slidesPerView: 1,
                      },
                      600: {
                        slidesPerView: 2,
                      },
                      800: {
                        slidesPerView: 2,
                      },
                      1100: {
                        slidesPerView: 3,
                      },
                      1200: {
                        slidesPerView: 3,
                      },
                      1300: {
                        slidesPerView: 4
                      },
                    }}
                  >
                    {lowStock.map((product, index) => {
                      return (
                        <SwiperSlide key={index}>
                          <div className="item" key={index}>
                            <div className="imgBox">
                              <Link to={"/DetailedProduct/" + product.ProductName.replace(/ /g, "-") + "/" + product.ProductId + "/" + product.DefaultVariation.ProductVariationId} >
                                <img src={Global.PHOTO_URL + product.DefaultVariation.Image1} alt={product.ProductName} />
                                {!product.InStock && (
                            <div className="out-of-stock-overlay">Out of Stock</div>
                        )}
                              </Link>
                            </div>

                            {

                              WishListItems.find(obj => obj.ProductId === product.ProductId) ? (
                                <button className="btnWishList"
                                  onClick={() => HandleRemoveFromWishList(product.ProductId)}
                                >
                                  <img
                                    src={activeWishListIcon}
                                    alt="In WishList"
                                  ></img>
                                </button>
                              ) : (
                                <button className="btnWishList"
                                  onClick={() => HandleAddToWishList(product.ProductId, product.DefaultVariation.ProductVariationId)}
                                >
                                  <img src={wishListIcon} alt="WishList"></img>
                                </button>
                              )
                            }
                            <div className="content">
                              <Link to={"/DetailedProduct/" + product.ProductName.replace(/ /g, "-") + "/" + product.ProductId}><h5>{product.ProductName}</h5></Link>
                              {/* <p className="sub">Playmegastore</p> */}
                              {product.DefaultVariation.SalePrice === 0 ? (
                                <div>
                                  <div>
                                    <div className="pricenormal">
                                      ${product.DefaultVariation.RegularPrice}{" "}
                                      {/* <button
                                            onClick={() => HandleSubmit(product.ProductId, product.DefaultVariation.ProductVariationId)}
                                          >
                                            <img src={addcart} alt="Add to Cart"></img>
                                          </button> */}
                                      <p className="price" style={{ minHeight: '50px' }}>
                                        <span className="off"></span>
                                        <span className="was"><span className="fw-medium"></span> <strike></strike></span>
                                      </p>
                                    </div>
                                  </div>
                                  <div className="text-center">
                                    <Link to={"/DetailedProduct/" + product.ProductName.replace(/ /g, "-") + "/" + product.ProductId + "/" + product.DefaultVariation.ProductVariationId}>
                                      <span className="btn btn-default choose-selection-button">Add to cart</span>
                                    </Link>
                                  </div>
                                </div>
                              ) : (
                                <>
                                  <p className="price" style={{ minHeight: '50px' }}>
                                    <span className="off">-{product.DefaultVariation.Percentage.toFixed(2) + "%"}</span>
                                    <span className="was"><span className="fw-medium">Was:</span> <strike>${product.DefaultVariation.RegularPrice}</strike></span>
                                    ${product.DefaultVariation.SalePrice}{" "}
                                    {/* <button
                                    onClick={() => HandleSubmit(product.ProductId, product.DefaultVariation.ProductVariationId)}
                                  >
                                    <img src={addcart} alt="Add to Cart"></img>
                                  </button> */}
                                  </p>
                                  <div className="text-center">
                                    <Link to={"/DetailedProduct/" + product.ProductName.replace(/ /g, "-") + "/" + product.ProductId + "/" + product.DefaultVariation.ProductVariationId}>
                                      <span className="btn btn-default choose-selection-button">Add to cart</span>
                                    </Link>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </SwiperSlide>
                      )
                    })}
                  </Swiper>
                )
              })}
            </div>
          </div>
          <div className="mobileNewsLetterSection d-md-none">
            <div className="container">
              <div className="row">
                <div className="col-12 text-center">
                  <img src={newsletter} alt="newsletter" />
                  <span>Sign Up To Our Newsletter</span>
                </div>
                <div className="col-12 form">
                  <form onSubmit={handleSubmitNewsLetter}>
                    <input type="text" placeholder="Enter your email address" onChange={(e) => setEmailNewsLetter(e.target.value)} />
                    <button type='submit'>Sign Up</button>
                  </form>
                </div>
              </div>
            </div>
          </div>

        </>
      )}
    </>
  );
};
export default Home;
